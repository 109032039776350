import { privacyPolicyExternalUrl, privacyPolicyUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(privacyPolicyUrl, { params });
export const getById = (id) => httpHelper.get(`${privacyPolicyUrl}/${id}`);
export const add = (data) => httpHelper.post(privacyPolicyUrl, data);
export const update = (id, data) => httpHelper.put(`${privacyPolicyUrl}/${id}`, data);
export const updateStatus = (id, data) =>
  httpHelper.put(`${privacyPolicyUrl}/${id}/update-status`, data);
export const remove = (id) => httpHelper.remove(`${privacyPolicyUrl}/${id}`);

// external
export const getAllExternal = (params) =>
  httpHelper.get(privacyPolicyExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${privacyPolicyExternalUrl}/${id}`, { isExternalApi: true });
