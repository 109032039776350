import React, { useState } from 'react';
import { Accordion, Row, Col, useAccordionButton, Card, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { faq1, faq2 } from '../../assets';
import { GradientImage } from '../../components';
import { shorthand } from '../../utils';
import './index.css';

const Faq = () => {
  const { t } = useTranslation();

  const Data = [
    {
      title: t('FAQ-001'),
      description: t('FAQ-002')
    },
    {
      title: t('FAQ-003'),
      description: t('FAQ-004')
    },
    {
      title: t('FAQ-005'),
      description: t('FAQ-006')
    },
    {
      title: t('FAQ-007'),
      description: t('FAQ-008')
    },
    {
      title: t('FAQ-009'),
      description: t('FAQ-010')
    },
    {
      title: t('FAQ-011'),
      description: t('FAQ-012')
    },
    {
      title: t('FAQ-013'),
      description: t('FAQ-014')
    },
    {
      title: t('FAQ-015'),
      description: t('FAQ-016')
    }
  ];
  // eslint-disable-next-line react/prop-types
  const CustomToggle = ({ children, eventKey }) => {
    const [active, setActive] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setActive(!active));
    const rotate = active ? 'rotate(180deg)' : 'rotate(0)';

    return (
      <div
        className="d-block m-5"
        onClick={decoratedOnClick}
        data-aos="fade-in"
        data-aos-duration={shorthand.aosDuration}>
        <FaChevronDown
          style={{ transform: rotate, transition: 'all 0.2s linear', marginRight: 46 }}
        />
        {children}
      </div>
    );
  };
  return (
    <>
      <div>
        <GradientImage
          image={faq1}
          caption={'Frequently Asked Questions'}
          description={t('FAQ-000')}
        />
      </div>
      <div className="my-xl-2 p-xl-5">
        <Row className="faq-apps-content-margin">
          <Col xxl="7" xl="7" lg="7" md="7" sm="10" xs="12">
            <Accordion flush>
              {Data.map((x, i) => (
                <React.Fragment key={i.toString()}>
                  <CustomToggle eventKey={i.toString()}>{x.title}</CustomToggle>
                  <Accordion.Collapse eventKey={i.toString()} className="mx-xl-5">
                    <Card.Body className="mx-xl-3 px-5 text">{x.description}</Card.Body>
                  </Accordion.Collapse>
                </React.Fragment>
              ))}
            </Accordion>
            <div
              className="d-inline-block mx-5 mt-2"
              data-aos="fade-right"
              data-aos-duration={shorthand.aosDuration}>
              {t('FAQ-017')}
              <span className="contact-us">
                <a href="/contact" className="link-contact">
                  {' '}
                  {t('CONTACT-008')}
                </a>
              </span>
            </div>
          </Col>
          <Col xxl="5" xl="5" lg="5" md="5" sm="2" xs="0">
            <Image
              className="p-xl-5 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block justify-content-md-center"
              src={faq2}
              fluid
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Faq;
