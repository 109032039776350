import React, { useEffect, useState } from 'react';
import { registerBackground } from '../../assets';
import { Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { BiUser, BiLockAlt, BiEnvelope, BiGlobe, BiBriefcaseAlt, BiWorld } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import './index.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { register as registerActions } from '../../stores/actions';
import Swal from 'sweetalert2';
import CountriesList from 'countries-list';
import PhoneInput from 'react-phone-input-2';
import { useForm } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import StepperTPN from '../../components/stepper';
import OptionsSelect from '../../components/OptionsSelect';
import optionCategory from '../../utils/utils';
import moment from 'moment';
import PasswordStrengthBar from 'react-password-strength-bar';
import { MdOpenInNew } from 'react-icons/md';
const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.generalReducer);
  const registerReducer = useSelector((state) => state.registerReducer);
  const [content, setContent] = useState(0);
  const { isLoading, error, data } = registerReducer;
  const navigate = useNavigate();

  const [fullname, setFullname, fullnameValid, fullnameTouched, setFullnameTouched, FullnameError] =
    useForm('', (x) => x);
  const [email, setEmail, emailValid, emailTouched, setEmailTouched, EmailError] = useForm(
    null,
    'email'
  );
  const [
    jobPosition,
    setJobPosition,
    jobPositionValid,
    jobPositionTouched,
    setJobPositionTouched,
    JobPositionError
  ] = useForm('', (x) => x.length >= 3);
  const [country, setCountry, countryValid, countryTouched, setCountryTouched, CountryError] =
    useForm('', (x) => x);
  const [phone, setPhone, phoneValid, phoneTouched, setPhoneTouched, PhoneError] = useForm(
    '',
    (x) => x.length >= 8
  );
  const [password, setPassword, passwordValid, passwordTouched, setPasswordTouched] = useForm(
    '',
    'password'
  );

  const [
    companyName,
    setCompanyname,
    companyNameValid,
    companyNameTouched,
    setCompanyNameTouched,
    CompanyNameError
  ] = useForm('', (x) => x);

  const [
    companyAliasName,
    setCompanyAliasname,
    companyAliasNameValid,
    companyAliasNameTouched,
    setCompanyAliasNameTouched,
    CompanyAliasNameError
  ] = useForm('', (x) => x);

  const [
    companyEmail,
    setCompanyEmail,
    companyEmailValid,
    companyEmailTouched,
    setCompanyEmailTouched,
    CompanyEmailError
  ] = useForm('', 'email');
  const [
    CompanyPhone,
    setCompanyPhone,
    companyPhoneValid,
    companyPhoneTouched,
    setcompanyPhoneTouched,
    CompanyPhoneError
  ] = useForm('', (x) => x.length >= 8);
  const [
    companyWebsite,
    setCompanyWebsite,
    companyWebsiteValid,
    companyWebsiteTouched,
    setCompanyWebsiteTouched,
    CompanyWebsiteError
  ] = useForm('', (x) => x);

  const [
    companyDescription,
    setCompanyDescription,
    companyDescriptionValid,
    companyDescriptionTouched,
    setCompanyDescriptionTouched,
    CompanyDescriptionError
  ] = useForm('', (x) => x);
  const [
    companyCategory,
    setCompanyCategory,
    companyCategoryValid,
    companyCategoryTouched,
    setCompanyCategoryTouched,
    CompanyCategoryError
  ] = useForm('', (x) => x);
  const [
    companyCountry,
    setCompanyCountry,
    companyCountryValid,
    companyCountryTouched,
    setCompanyCountryTouched,
    CompanyCountryError
  ] = useForm('', (x) => x);
  const [
    companyState,
    setCompanyState,
    companyStateValid,
    companyStateTouched,
    setCompanyStateTouched,
    CompanyStateError
  ] = useForm('', (x) => x);
  const [
    companyCity,
    setCompanyCity,
    companyCityValid,
    companyCityTouched,
    setCompanyCityTouched,
    CompanyCityError
  ] = useForm('', (x) => x);
  const [
    companyPostalCode,
    setCompanyPostalCode,
    companyPostalCodeValid,
    companyPostalCodeTouched,
    setCompanyPostalCodeTouched,
    CompanyPostalCodeError,
    setCompanyPostalCodeValid
  ] = useForm('', (x) =>
    companyCountry && companyCountry.name === 'Indonesia'
      ? /^[0-9]+$/.test(x) && x.length >= 3
      : x && x.length >= 3
  );
  const [
    companyFullAddress,
    setCompanyFullAddress,
    companyFullAddressValid,
    companyFullAddressTouched,
    setCompanyFullAddressTouched,
    CompanyFullAddressError
  ] = useForm('', (x) => x);
  const [
    joinReason,
    setJoinReason,
    joinReasonValid,
    joinReasonTouched,
    setJoinReasonTouched,
    JoinReasonError
  ] = useForm('', (x) => x);
  const [tpnInfoLocation, setTpnInfoLocation] = useState([
    { selected: false, value: "Telkom's contact person" },
    { selected: false, value: 'Search engine results' },
    { selected: false, value: 'Your company colleague' },
    { selected: false, value: '' }
  ]);
  const [otherInfoReceived, setOtherInfoReceived] = useState('');

  const [
    confirmPassword,
    setConfirmPassword,
    confirmPasswordValid,
    confirmPasswordTouched,
    setConfirmPasswordTouched,
    ConfirmPasswordError,
    setIsValidConfirmPassword
  ] = useForm('', (x) => x && x === password);
  const [showPassword, setShowPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');
  const [captcha, setCaptcha, captchaValid, captchaTouched, setCaptchaTouched, CaptchaError] =
    useForm(null, (x) => x);
  const [isValid, setIsValid] = useState(false);
  const [isValidCompany, setIsValidCompany] = useState(false);
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [checkFilled, SetIsCheckFilled] = useState(false);
  const [checkRead, SetIsCheckRead] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const handleShowPassword = (type) => {
    if (type === 'password') {
      if (showPassword === 'password') {
        return setShowPassword('text');
      }
      return setShowPassword('password');
    }
    if (type === 'confirm') {
      if (showConfirmPassword === 'password') {
        return setShowConfirmPassword('text');
      }
      return setShowConfirmPassword('password');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFullnameTouched(true);
    setEmailTouched(true);
    setCountryTouched(true);
    setPhoneTouched(true);
    setPasswordTouched(true);
    setConfirmPasswordTouched(true);
    setJobPositionTouched(true);
    setCompanyNameTouched(true);
    setCompanyAliasNameTouched(true);
    setCompanyEmailTouched(true);
    setcompanyPhoneTouched(true);
    setCompanyCategoryTouched(true);
    setCompanyCountryTouched(true);
    setCompanyStateTouched(true);
    setCompanyCityTouched(true);
    setCompanyPostalCodeTouched(true);
    setCompanyFullAddressTouched(true);
    setCaptchaTouched(true);
    if (!isValid) {
      return false;
    }
    if (!isValidCompany) return false;
    if (!isValidCaptcha) return false;
    dispatch(
      registerActions.fetchDataRegister({
        user: {
          fullname: fullname,
          email: email,
          jobPosition,
          country: country?.name,
          password,
          phoneNumber: phone
        },
        partner: {
          companyName: companyName,
          companyAliasName: companyAliasName,
          companyEmail: companyEmail,
          companyPhoneNumber: CompanyPhone,
          companyWebsite: companyWebsite,
          companyDescription: companyDescription,
          companyCategory: companyCategory.map((v) => v.value),
          companyAddress: {
            state: companyState,
            country: companyCountry?.name,
            city: companyCity,
            postalCode: companyPostalCode,
            street: companyFullAddress
          },
          reasonToJoin: joinReason,
          source: tpnInfoLocation.filter((v) => v.value !== '' && v.selected).map((v) => v.value)
        },
        captcha: captcha || '123'
      })
    );
    return;
  };

  const handleNextStep = (x, stepper = false) => {
    if (stepper) {
      switch (x) {
        case 3:
          if (!validateSteps(0) || !validateSteps(1) || !validateSteps(2)) return false;
          break;
        case 1:
          if (!validateSteps(0)) return false;
          break;
        case 2:
          if (!validateSteps(1) || !validateSteps(0)) return false;
          break;
        default:
          break;
      }
      return setContent(x);
    }
    if (validateSteps(x)) setContent(x + 1);
  };

  const validateSteps = (x) => {
    switch (x) {
      case 0:
        setFullnameTouched(true);
        setEmailTouched(true);
        setJobPositionTouched(true);
        setCountryTouched(true);
        setPhoneTouched(true);
        setPasswordTouched(true);
        setConfirmPasswordTouched(true);
        SetIsCheckFilled(false);
        SetIsCheckRead(false);
        setPrivacyCheck(false);
        // setCaptchaTouched(true);
        if (!isValid) return false;
        break;
      case 1:
        setCompanyNameTouched(true);
        setCompanyAliasNameTouched(true);
        setCompanyEmailTouched(true);
        setcompanyPhoneTouched(true);
        setCompanyWebsiteTouched(true);
        setCompanyDescriptionTouched(true);
        setCompanyCategoryTouched(true);
        setCompanyCountryTouched(true);
        setCompanyStateTouched(true);
        setCompanyCityTouched(true);
        setCompanyPostalCodeTouched(true);
        setCompanyFullAddressTouched(true);
        SetIsCheckFilled(false);
        SetIsCheckRead(false);
        setPrivacyCheck(false);
        if (!isValidCompany) return false;
        break;
      case 2:
        setJoinReasonTouched(true);
        if (joinReason === '') return false;
        if (tpnInfoLocation.find((v) => v.selected === true) === undefined) return false;
        if (tpnInfoLocation[3].selected && otherInfoReceived === '') return false;
        SetIsCheckFilled(false);
        SetIsCheckRead(false);
        setPrivacyCheck(false);
        break;
      default:
        break;
    }
    return true;
  };
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      }).then(() => {
        dispatch(registerActions.fetchRegisterFail(null));
      });
    }
    if (data) {
      Swal.fire({
        icon: 'success',
        title: 'Sucess',
        text: 'Successfully created account!'
      }).then(() => {
        localStorage.setItem(
          'resendactivationtimer',
          new Date(
            moment(moment().utcOffset('7').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).add(5, 'minute')
          )
        );
        return navigate(`/activation?email=${email}`);
      });
    }
  }, [error, data]);

  useEffect(() => {
    setIsValid(
      fullnameValid &&
        emailValid &&
        phoneValid &&
        passwordValid &&
        confirmPasswordValid &&
        countryValid &&
        jobPositionValid
    );
  }, [fullname, email, phone, password, confirmPassword, country, jobPosition]);

  useEffect(() => {
    setIsValidCompany(
      companyNameValid &&
        companyAliasNameValid &&
        companyEmailValid &&
        companyPhoneValid &&
        companyCountryValid &&
        companyStateValid &&
        companyCityValid &&
        companyPostalCodeValid &&
        companyFullAddressValid &&
        companyCategoryValid
    );
  }, [
    companyName,
    companyAliasName,
    companyEmail,
    CompanyPhone,
    companyCountry,
    companyState,
    companyCity,
    companyPostalCode,
    companyPostalCodeValid,
    companyFullAddress,
    companyCategory
  ]);

  useEffect(() => {
    setIsValidCaptcha(captchaValid || process.env.REACT_APP_RECAPTCHA_ENABLE === 'false');
  }, [captcha]);

  useEffect(() => {
    if (content !== 2) setCaptcha(null);
  }, [content]);

  useEffect(() => {
    if (confirmPassword !== '' && confirmPassword !== password) setIsValidConfirmPassword(false);
    if (confirmPassword !== '' && confirmPassword === password) setIsValidConfirmPassword(true);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (companyCountry?.name === 'Indonesia') {
      setCompanyPostalCodeValid(
        /^[0-9]+$/.test(companyPostalCode) && companyPostalCode.length >= 3
      );
    } else {
      setCompanyPostalCodeValid(companyPostalCode.length >= 3);
    }
  }, [companyCountry]);

  const registerPic = [
    {
      id: 1,
      title: 'Personal Info'
    },
    {
      id: 2,
      title: 'Company Info'
    },
    {
      id: 3,
      title: 'Additional Info'
    },
    {
      id: 4,
      title: 'Confirmation'
    }
  ];

  const setCon = (x) => {
    handleNextStep(x, true);
  };

  const onInfoChange = (e) => {
    const newTpnInfoLocation = [...tpnInfoLocation];
    newTpnInfoLocation[e].selected = !newTpnInfoLocation[e].selected;
    setTpnInfoLocation(newTpnInfoLocation);
  };

  useEffect(() => {
    if (otherInfoReceived !== '') {
      const newTpnInfoLocation = [...tpnInfoLocation];
      newTpnInfoLocation[3].selected = true;
      newTpnInfoLocation[3].value = otherInfoReceived;
      setTpnInfoLocation(newTpnInfoLocation);
    } else {
      const newTpnInfoLocation = [...tpnInfoLocation];
      newTpnInfoLocation[3].selected = false;
      newTpnInfoLocation[3].value = '';
      setTpnInfoLocation(newTpnInfoLocation);
    }
  }, [otherInfoReceived]);

  const renderContentStepper = () => {
    switch (content) {
      case 0:
        return (
          <div>
            <Form.Group controlId="">
              <h4>Personal Info</h4>
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicFullName">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-025')}
              </Form.Label>
              <InputGroup
                className={`${
                  !fullnameValid && fullnameTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={fullname}
                  placeholder={t('LANG-029')}
                  className="border-start-0"
                  onChange={(e) => setFullname(e.target.value)}
                  onBlur={() => setFullnameTouched(true)}
                />
              </InputGroup>
              <FullnameError message="Please fill your full name" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-026')}
              </Form.Label>
              <InputGroup
                className={`${!emailValid && emailTouched ? 'border border-danger rounded' : ''}`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiEnvelope />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder={t('LANG-030')}
                  className="border-start-0"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setEmailTouched(true)}
                />
              </InputGroup>
              <EmailError message="Please fill your email address correctly" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicPhone">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-027')}
              </Form.Label>
              <PhoneInput
                country={'id'}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                placeholder={t('LANG-037')}
                masks={{ id: '... .... .... ....' }}
                inputClass="w-100"
                onBlur={() => setPhoneTouched(true)}
                containerClass={`${
                  !phoneValid && phoneTouched ? 'border border-danger rounded' : ''
                }`}
              />
              <PhoneError message="Please fill your phone number correctly" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCountry">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-036')}
              </Form.Label>
              <InputGroup
                className={`${
                  !countryValid && countryTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiGlobe />
                </InputGroup.Text>
                <Form.Select
                  aria-label="Select Country"
                  className="border-start-0"
                  onChange={(e) => {
                    e.preventDefault();
                    const code = e.currentTarget.value;
                    const name = CountriesList.countries[code].name;
                    setCountry({ code, name });
                    setCountryTouched(true);
                  }}
                  onClick={() => setCountryTouched(true)}
                  value={country?.code}>
                  <option>{t('LANG-031')}</option>
                  {Object.keys(CountriesList.countries)
                    .map((x) => ({
                      code: x,
                      name: CountriesList.countries[x].name
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.code} value={x.code}>
                        {x.name}
                      </option>
                    ))}
                </Form.Select>
              </InputGroup>
              <CountryError message="Please select a country" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicJobPosition">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-131')}
              </Form.Label>
              <InputGroup
                className={`${
                  !jobPositionValid && jobPositionTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiBriefcaseAlt />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={jobPosition}
                  placeholder={t('RGSTR-WEB-132')}
                  className="border-start-0"
                  onChange={(e) => setJobPosition(e.target.value)}
                  onBlur={() => setJobPositionTouched(true)}
                />
              </InputGroup>
              <JobPositionError
                message={
                  jobPosition !== '' && jobPosition.length < 3
                    ? 'Please fill Job Position field correctly with minimum 3 characters length'
                    : 'Please fill your job position'
                }
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-022')}
              </Form.Label>
              <InputGroup
                className={`${
                  !passwordValid && passwordTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiLockAlt />
                </InputGroup.Text>
                <Form.Control
                  type={showPassword}
                  value={password}
                  placeholder={t('LANG-032')}
                  className="border-end-0 border-start-0"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => setPasswordTouched(true)}
                />
                <InputGroup.Text
                  className="bg-transparent border-start-0"
                  onClick={() => handleShowPassword('password')}
                  role="button">
                  {showPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}
                </InputGroup.Text>
              </InputGroup>
              {password !== '' ? (
                <>
                  <PasswordStrengthBar
                    password={password}
                    minLength={8}
                    scoreWords={[
                      <span key="password-key" style={{ color: '#ef4836' }}>
                        <strong>Weak</strong>
                      </span>,
                      <span key="password-key" style={{ color: '#F7B500' }}>
                        <strong>Okay</strong>
                      </span>,
                      <span key="password-key" style={{ color: '#2b90ef' }}>
                        <strong>Good</strong>
                      </span>,
                      <span key="password-key" style={{ color: '#0B66AC' }}>
                        <strong>Strong</strong>
                      </span>,
                      <span key="password-key" style={{ color: '#25c281' }}>
                        <strong>Stronger</strong>
                      </span>
                    ]}
                    shortScoreWord={
                      <span key="password-key" style={{ color: '#ef4836' }}>
                        <strong>Too short</strong>
                      </span>
                    }
                    barColors={['#ddd', '#F7B500', '#2b90ef', '#0B66AC', '#25c281']}
                    className={password ? 'password-bar mt-1' : 'password-bar opacity-0 mt-1'}
                  />
                </>
              ) : (
                ''
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: t('LANG-035').replace(
                    /text-muted/g,
                    !passwordValid && passwordTouched ? 'text-danger' : 'text-muted'
                  )
                }}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicConfirmPassword">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('LANG-028')}
              </Form.Label>
              <InputGroup
                className={`${
                  !confirmPasswordValid && confirmPasswordTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiLockAlt />
                </InputGroup.Text>
                <Form.Control
                  type={showConfirmPassword}
                  value={confirmPassword}
                  placeholder={t('LANG-033')}
                  className="border-end-0 border-start-0"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={() => setConfirmPasswordTouched(true)}
                />
                <InputGroup.Text
                  className="bg-transparent border-start-0"
                  onClick={() => handleShowPassword('confirm')}
                  role="button">
                  {showConfirmPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}
                </InputGroup.Text>
              </InputGroup>
              <ConfirmPasswordError message="Please re-enter your password correctly" />
            </Form.Group>
          </div>
        );
      case 1:
        return (
          <div>
            <Form.Group controlId="">
              <h4>
                <strong>{t('RGSTR-WEB-100')}</strong>{' '}
              </h4>
            </Form.Group>
            <Form.Group controlId="">
              <h5 style={{ color: '#8F95B2' }}>
                <strong>{t('RGSTR-WEB-101')}</strong>{' '}
              </h5>
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyLegalName">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp; {t('RGSTR-WEB-102')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyNameValid && companyNameTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={companyName}
                  placeholder={t('RGSTR-WEB-103')}
                  className="border-start-0"
                  onChange={(e) => setCompanyname(e.target.value)}
                  onBlur={() => setCompanyNameTouched(true)}
                />
              </InputGroup>
              <CompanyNameError message="Please fill your company legal name" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyAliasName">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp; {t('RGSTR-WEB-104')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyAliasNameValid && companyAliasNameTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiUser />
                </InputGroup.Text>
                <Form.Control
                  value={companyAliasName}
                  type="text"
                  placeholder={t('RGSTR-WEB-105')}
                  className="border-start-0"
                  onChange={(e) => setCompanyAliasname(e.target.value)}
                  onBlur={() => setCompanyAliasNameTouched(true)}
                />
              </InputGroup>
              <CompanyAliasNameError message='Type "-" if company doesnt have an alias' />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyEmail">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-106')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyEmailValid && companyEmailTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiEnvelope />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  value={companyEmail}
                  placeholder={t('RGSTR-WEB-107')}
                  className="border-start-0"
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  onBlur={() => setCompanyEmailTouched(true)}
                />
              </InputGroup>
              <CompanyEmailError message="Please input valid company email" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyPhone">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-108')}
              </Form.Label>
              <PhoneInput
                country={'id'}
                value={CompanyPhone}
                onChange={(CompanyPhone) => setCompanyPhone(CompanyPhone)}
                placeholder={t('RGSTR-WEB-037')}
                masks={{ id: '... .... .... ....' }}
                inputClass="w-100"
                onBlur={() => setcompanyPhoneTouched(true)}
                containerClass={`${
                  !companyPhoneValid && companyPhoneTouched ? 'border border-danger rounded' : ''
                }`}
              />
              <CompanyPhoneError message="Please fill your company phone number correctly" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyWebsite">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-109')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyWebsiteValid && companyWebsiteTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiWorld />
                </InputGroup.Text>
                <Form.Control
                  value={companyWebsite}
                  type="text"
                  placeholder={t('RGSTR-WEB-110')}
                  className="border-start-0"
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  onBlur={() => setCompanyWebsiteTouched(true)}
                />
              </InputGroup>
              <CompanyWebsiteError message="Please fill your company website" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasiCompanyDescription">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-111')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyDescriptionValid && companyDescriptionTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0"></InputGroup.Text>
                <Form.Control
                  as="textarea"
                  placeholder={t('RGSTR-WEB-112')}
                  className="border-start-0"
                  value={companyDescription}
                  onChange={(e) => setCompanyDescription(e.target.value)}
                  onBlur={() => setCompanyDescriptionTouched(true)}
                />
              </InputGroup>
              <CompanyDescriptionError message='Type "-" if doesnt have description' />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicCompanyAliasName">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-113')}
              </Form.Label>
              <div
                className={`${
                  !companyCategoryValid && companyCategoryTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <OptionsSelect
                  placeholder={t('RGSTR-WEB-114')}
                  isMulti
                  options={optionCategory.map((v) => ({
                    value: v.name,
                    label: v.name
                  }))}
                  value={companyCategory}
                  onChange={setCompanyCategory}
                  onClick={() => setCompanyCategoryTouched(true)}
                />
              </div>
              <CompanyCategoryError message="Please select company category" />
            </Form.Group>
            <Form.Group controlId="">
              <h5 style={{ color: '#8F95B2' }}>
                <strong>{t('RGSTR-WEB-115')}</strong>{' '}
              </h5>
            </Form.Group>
            <div className="my-3 d-flex flex-row justify-content-between">
              <Col>
                <Form.Group controlId="formBasicCompanyCountry" style={{ marginRight: '5px' }}>
                  <Form.Label className="fw-semibold">
                    <span className="text-danger">*</span> {t('RGSTR-WEB-116')}
                  </Form.Label>
                  <InputGroup
                    className={`${
                      !companyCountryValid && companyCountryTouched
                        ? 'border border-danger rounded'
                        : ''
                    }`}>
                    <InputGroup.Text className="bg-transparent border-end-0">
                      <BiGlobe />
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Select Country"
                      className="border-start-0"
                      onChange={(e) => {
                        e.preventDefault();
                        const code = e.currentTarget.value;
                        const name = CountriesList.countries[code].name;
                        setCompanyCountry({ code, name });
                        setCompanyCountryTouched(true);
                      }}
                      onClick={() => setCompanyCountryTouched(true)}
                      value={companyCountry?.code}>
                      <option>{t('RGSTR-WEB-117')}</option>
                      {Object.keys(CountriesList.countries)
                        .map((x) => ({
                          code: x,
                          name: CountriesList.countries[x].name
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => (
                          <option key={x.code} value={x.code}>
                            {x.name}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                  <CompanyCountryError message="Please select a company country" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicCompanyState" style={{ marginLeft: '5px' }}>
                  <Form.Label className="fw-semibold">
                    <span className="text-danger">*</span>&nbsp; {t('RGSTR-WEB-118')}
                  </Form.Label>
                  <InputGroup
                    className={`${
                      !companyStateValid && companyStateTouched
                        ? 'border border-danger rounded'
                        : ''
                    }`}>
                    <InputGroup.Text className="bg-transparent border-end-0">
                      {/* <BiEnvelope /> */}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={t('RGSTR-WEB-119')}
                      value={companyState}
                      className="border-start-0"
                      onChange={(e) => setCompanyState(e.target.value)}
                      onBlur={() => setCompanyStateTouched(true)}
                    />
                  </InputGroup>
                  <CompanyStateError message="Please fill your company state" />
                </Form.Group>
              </Col>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between">
              <Col>
                <Form.Group controlId="formBasicCompanyCity" style={{ marginRight: '5px' }}>
                  <Form.Label className="fw-semibold">
                    <span className="text-danger">*</span>&nbsp; {t('RGSTR-WEB-120')}
                  </Form.Label>
                  <InputGroup
                    className={`${
                      !companyCityValid && companyCityTouched ? 'border border-danger rounded' : ''
                    }`}>
                    <InputGroup.Text className="bg-transparent border-end-0">
                      {/* <BiEnvelope /> */}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={t('RGSTR-WEB-121')}
                      value={companyCity}
                      className="border-start-0"
                      onChange={(e) => setCompanyCity(e.target.value)}
                      onBlur={() => setCompanyCityTouched(true)}
                    />
                  </InputGroup>
                  <CompanyCityError message="Please fill your company city" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicCompanyPostalCode" style={{ marginLeft: '5px' }}>
                  <Form.Label className="fw-semibold">
                    <span className="text-danger">*</span>&nbsp; {t('RGSTR-WEB-122')}
                  </Form.Label>
                  <InputGroup
                    className={`${
                      !companyPostalCodeValid && companyPostalCodeTouched
                        ? 'border border-danger rounded'
                        : ''
                    }`}>
                    <InputGroup.Text className="bg-transparent border-end-0">
                      {/* <BiEnvelope /> */}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={companyPostalCode}
                      placeholder={t('RGSTR-WEB-123')}
                      className="border-start-0"
                      onChange={(e) => setCompanyPostalCode(e.target.value)}
                      onBlur={() => setCompanyPostalCodeTouched(true)}
                    />
                  </InputGroup>
                  <CompanyPostalCodeError message="Please fill your valid company postal code and at least 3 characters" />
                </Form.Group>
              </Col>
            </div>
            <Form.Group className="my-3" controlId="formBasicCompanyFullAddress">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-124')}
              </Form.Label>
              <InputGroup
                className={`${
                  !companyFullAddressValid && companyFullAddressTouched
                    ? 'border border-danger rounded'
                    : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0"></InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={companyFullAddress}
                  placeholder={t('RGSTR-WEB-125')}
                  className="border-start-0"
                  onChange={(e) => setCompanyFullAddress(e.target.value)}
                  onBlur={() => setCompanyFullAddressTouched(true)}
                />
              </InputGroup>
              <CompanyFullAddressError message="Please fill your company address" />
            </Form.Group>
          </div>
        );
      case 2:
        return (
          <div>
            <Form.Group controlId="">
              <h4>Additional Information</h4>
            </Form.Group>
            <Form.Group className="my-3" controlId="formReason">
              <Form.Label className="fw-semibold">
                <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-133')}
              </Form.Label>
              <InputGroup
                className={`${
                  !joinReasonValid && joinReasonTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0"></InputGroup.Text>
                <Form.Control
                  as="textarea"
                  placeholder={t('RGSTR-WEB-133')}
                  className="border-start-0"
                  value={joinReason}
                  onChange={(e) => setJoinReason(e.target.value)}
                  onBlur={() => setJoinReasonTouched(true)}
                />
              </InputGroup>
              <JoinReasonError message="Please tell us the reason why you want to join us" />
            </Form.Group>
            <Form.Label className="fw-semibold">
              <span className="text-danger">*</span>&nbsp;{t('RGSTR-WEB-134')}
            </Form.Label>
            <Form.Group className="my-2" controlId="formWhere1">
              <Form.Check
                type="checkbox"
                value="Telkom's contact person"
                label="Telkom's contact person"
                color="red"
                onChange={() => onInfoChange(0)}
                checked={tpnInfoLocation[0].selected}
              />
            </Form.Group>
            <Form.Group className="my-2" controlId="formWhere2">
              <Form.Check
                type="checkbox"
                value="Search engine results"
                label="Search engine results"
                onChange={() => onInfoChange(1)}
                checked={tpnInfoLocation[1].selected}
              />
            </Form.Group>
            <Form.Group className="my-2" controlId="formWhere3">
              <Form.Check
                type="checkbox"
                value="Your company colleague"
                label="Your company colleague"
                onChange={() => onInfoChange(2)}
                checked={tpnInfoLocation[2].selected}
              />
            </Form.Group>
            <Form.Group className="my-2 d-flex" controlId="formWhere4">
              <Form.Check
                type="checkbox"
                inline
                onChange={() => onInfoChange(3)}
                checked={tpnInfoLocation[3].selected}
              />
              <InputGroup>
                <Form.Control
                  value={otherInfoReceived}
                  type="text"
                  inline
                  placeholder="Others..."
                  onChange={(e) => setOtherInfoReceived(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            {tpnInfoLocation[3].selected && otherInfoReceived === '' && (
              <span className="text-danger">Please fill this field</span>
            )}
          </div>
        );
      case 3:
        return (
          <div>
            <Form.Group controlId="">
              <h4>{t('RGSTR-WEB-130')}</h4>
            </Form.Group>
            <Form.Group className="my-2" controlId="form1">
              <Form.Check
                type="checkbox"
                inline
                label={
                  <span>
                    {t('RGSTR-WEB-138')}{' '}
                    <a
                      target="_blank"
                      href="/terms-and-conditions"
                      className="text-decoration-none text-danger fw-bold">
                      {t('AUTH-LOGIN-016')}
                      &nbsp;
                      <MdOpenInNew
                        role="button"
                        className="text-dark"
                        href="/terms-and-conditions"
                        target="_blank"
                      />
                    </a>
                  </span>
                }
                onChange={() => SetIsCheckRead(!checkRead)}
                checked={checkRead}
              />
            </Form.Group>
            <Form.Group className="my-2" controlId="form2">
              <Form.Check
                type="checkbox"
                inline
                label={
                  <span>
                    {t('RGSTR-WEB-138')}{' '}
                    <a
                      target="_blank"
                      href="/privacy-policy"
                      className="text-decoration-none text-danger fw-bold">
                      {t('AUTH-LOGIN-017')}
                      &nbsp;
                      <MdOpenInNew
                        role="button"
                        className="text-dark"
                        href="/privacy-policy"
                        target="_blank"
                      />
                    </a>
                  </span>
                }
                onChange={() => setPrivacyCheck(!privacyCheck)}
                checked={privacyCheck}
              />
            </Form.Group>
            <Form.Group controlId="form3">
              <Form.Check
                type="checkbox"
                inline
                label="I have filled the personal and company forms correctly"
                onChange={() => SetIsCheckFilled((current) => !current)}
                checked={checkFilled}
              />
            </Form.Group>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && (
                <div
                  className={`my-3 ${
                    !captchaValid && captchaTouched ? 'border border-danger' : ''
                  }`}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => {
                      setCaptcha(value);
                      setCaptchaTouched(true);
                    }}
                    onExpired={() => setCaptcha(null)}
                    hl={general.language?.code || 'en'}
                  />
                  <CaptchaError message="Please verify that you are not a robot" />
                </div>
              )}
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <div className="d-flex flex-row row-container-form">
      <Col xxl="6" xl="6" lg="6" className="container-image-bg-register">
        <div
          className="image-cover-fluid"
          style={{ '--img': `url(${registerBackground})`, height: '91.5vh' }}>
          <div
            className="image-overlay"
            style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
            <div className="image-caption">Get Started</div>
            <div className="image-description mt-4">
              Let’s partner with us, your next big great step start here.
            </div>
          </div>
        </div>
      </Col>
      <Col className="p-regis col-container-form" xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
        <div className="mx-lg-5 mb-4">
          <h1 className="fw-bold">{t('LANG-007')}</h1>
          <span className="text-secondary">
            {t('LANG-048')}{' '}
            <span
              role="button"
              onClick={() => navigate('/guides/register')}
              className="text-red fw-bold">
              {t('LANG-004')}
            </span>
          </span>
        </div>
        <div>
          <StepperTPN steps={registerPic} activeStep={content} setParentActiveStep={setCon} />
        </div>

        <Form className="my-3 mx-lg-5" onSubmit={handleSubmit}>
          {renderContentStepper()}
          <div className="my-5 d-flex flex-row justify-content-between">
            <Button
              hidden={content === 0}
              variant="outline"
              className="text-uppercase banner-button-out fw-bold"
              onClick={() => {
                setContent(content - 1);
              }}>
              {t('RGSTR-WEB-126')}
            </Button>
            <Button
              disabled={(!checkFilled || !checkFilled) && content === 3}
              variant="danger"
              type={content === 3 ? 'submit' : 'button'}
              style={{ marginLeft: 'auto' }}
              className={`text-uppercase banner-button fw-bold ${
                (!checkFilled || !checkRead || !privacyCheck) && content === 3
                  ? 'banner-button-disable'
                  : ''
              }`}
              onClick={() => {
                content === 3 ? handleSubmit : handleNextStep(content);
              }}>
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Loading...
                </>
              ) : content === 3 ? (
                t('LANG-038')
              ) : (
                t('RGSTR-WEB-127')
              )}
            </Button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Form.Label className="text-secondary">
              {t('LANG-034')}{' '}
              <a
                href="/login"
                className="text-decoration-none text-danger font-weight-bold fw-bold">
                {t('LANG-006')}
              </a>
            </Form.Label>
          </div>
        </Form>
      </Col>
    </div>
  );
};

export default Register;
