const optionCategory = [
  {
    name: 'Acomodation and Food Service Activities'
  },
  {
    name: 'Arts, Entertainment and Recreation'
  },
  {
    name: 'Communication and Technologies'
  },
  {
    name: 'Digital Ecosystems'
  },
  {
    name: 'Education'
  },
  {
    name: 'Digital and Insurance Activities'
  },
  {
    name: 'Government Institution'
  },
  {
    name: 'Human Health and Social Work Activities'
  },
  {
    name: 'Information Technologies'
  },
  {
    name: 'Manufacturing'
  },
  {
    name: 'Other service activity'
  },
  {
    name: 'Profesional Scientific and Technical Activities'
  },
  {
    name: 'Wholesale and Retail Trade'
  },
  {
    name: 'Transportation Service'
  }
];

export default optionCategory;
