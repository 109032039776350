import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InformationScreen } from '../../components';
import { recoveryEmail, resetPasswordSuccess } from '../../assets';
import { Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { activation as activationActions } from '../../stores/actions';
import Swal from 'sweetalert2';
import { ACTIVATION } from '../../stores/types';
import moment from 'moment';
import CountDownTimer from '../../components/CountdownTimer';
const Activation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const { activation, resend } = useSelector((state) => state.activationReducer);
  const [targetDate, setTargetDate] = useState(
    new Date(localStorage.getItem('resendactivationtimer')).getTime()
  );
  const dispatch = useDispatch();

  const handleResend = () => {
    if (resend.isLoading) {
      return;
    }
    localStorage.setItem(
      'resendactivationtimer',
      new Date(
        moment(moment().utcOffset('7').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).add(5, 'minute')
      )
    );
    setTargetDate(localStorage.getItem('resendactivationtimer'));
    dispatch(activationActions.fetchDataResend({ email: decodeURI(email) }));
  };

  const handleActivate = () => {
    if (activation.isLoading) {
      return;
    }
    dispatch(
      activationActions.fetchDataActivation({ email: decodeURI(email), token: decodeURI(token) })
    );
  };

  useEffect(() => {
    if (token && email) {
      handleActivate();
    }
  }, [token]);

  useEffect(() => {
    if (resend.error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: resend.error,
        customClass: {
          confirmButton: 'banner-button'
        },
        confirmButtonText: 'OKAY'
      }).then(() => {
        dispatch(activationActions.fetchFail(ACTIVATION.FETCH_RESEND.FAILED, null));
      });
    }
    if (resend.data) {
      Swal.fire({
        icon: 'success',
        title: `<h2 style='color:#00C853'>Email has been resent</h2>`,
        text: 'We sent you an email with instruction on how to activate your account.',
        customClass: {
          confirmButton: 'banner-button'
        },
        confirmButtonText: 'OKAY'
      }).then(() => {
        dispatch(activationActions.fetchSuccess(ACTIVATION.FETCH_RESEND.SUCCESS, null));
      });
    }
  }, [resend.data, resend.error]);

  const getTitleActivation = () => {
    if (activation.data) {
      return 'Account Activated';
    }
    if (activation.error) {
      return 'Oops...';
    }
    return '';
  };

  return (
    <InformationScreen
      title={!token ? 'Verify your email' : getTitleActivation()}
      image={!token ? recoveryEmail : resetPasswordSuccess}
      caption={
        <>
          {!token ? (
            <>
              Your request to create new account has been successful. We sent you an email{' '}
              <span className="fw-bold">({email})</span> with instruction on how to activate your
              account.
            </>
          ) : (
            <>
              {activation.isLoading && 'Please wait...'}
              {activation.data &&
                'Your email has been successfully verified and your account is active now. Please login to start your journey with us.'}
              {activation.error && `${activation.error}`}
            </>
          )}
        </>
      }
      button={{
        text: token ? 'LOGIN' : 'GOT IT',
        action: () => navigate('/login')
      }}>
      {!token && (
        <>
          <br />
          <Form.Label className="mt-5 justify-content-center text-secondary">
            {targetDate ? (
              <CountDownTimer
                targetDate={targetDate}
                keyLocalstorage="resendactivationtimer"
                callback={setTargetDate}
              />
            ) : (
              <>
                Not receiving an email?&nbsp;
                <a
                  className="text-decoration-none text-danger font-weight-bold"
                  role="button"
                  onClick={handleResend}>
                  {resend.isLoading && (
                    <>
                      <Spinner size="sm" />
                      &nbsp;
                    </>
                  )}
                  Resend
                </a>
              </>
            )}
          </Form.Label>
        </>
      )}
    </InformationScreen>
  );
};

export default Activation;
